import '../App.css';
import React, { useCallback } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import packageJson from '../../package.json';

const Welcome = () => {
	let baseUrl = window.location.origin.split('.').slice(0, -2).join('.');

	const tld1domain = window.location.hostname.split('.').slice(1, -2).join('.');

	const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');

	const hostname = `${subdomain}.${tld1domain}`;
	if (tld1domain === 'csb.app') {
		baseUrl = `https://${hostname}`;
	}

	if (tld1domain === 'auth.onl') {
		baseUrl = `https://auth.${subdomain}`;
	}

	if (subdomain === 'app.localsecurity.org') {
		baseUrl = `https://auth.${subdomain}`;
	}

	const exampleText = `${baseUrl}/${packageJson.homepage}`;

	const [value, copy] = useCopyToClipboard();
	const onCopy = useCallback(async () => {
		copy(exampleText);
	}, [copy, exampleText]);

	return (
		<div className="app-content" data-testid="welcome-component">
			<h1 className="welcome-title">Welcome</h1>
			<p className="text-body">
				To display your login flow please specify your VSOC <b>project id</b>.
			</p>
			<p className="example-title">Here&apos;s an example:</p>
			<p
				data-testid="welcome-copy-component"
				className="example"
				onClick={onCopy}
				aria-hidden="true"
			>
				{exampleText}
				{value ? (
					<CheckIcon data-testid="welcome-copied-icon" className="copy-icon" />
				) : (
					<ContentCopyIcon
						data-testid="welcome-copy-icon"
						className="copy-icon"
					/>
				)}
			</p>
		</div>
	);
};

export default Welcome;
